.popupHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.popupBox {
  padding: 25px;
  border: none;
  margin-bottom: 250px;
}

.popupBody {
  padding: 0;
}

.popupLabel {
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: "gm";
  font-size: 13px;
  font-weight: 400;
  color: black;
}

.popupHeadText {
  font-family: "gsb";
  font-size: 20px;
  color: black;
}

.popupInput {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  height: 35px;
  padding: 5px;
  color: black;
  font-family: "gm";
}

.popupInput:focus-visible {
  border-color: #fcc216 !important;
}

.popupSubmit {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 3px;
  width: 100%;
  height: 42px;
  margin-top: 25px;
  font-family: "gm";
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.popupClose {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px;
}

.tyThumbnail {
  width: 197px;
  height: 116px;
}

.ytTitleDiv {
  max-width: 195px;
  display: flex;
  padding: 0px 5px;
}

.ytRedirect:hover {
  cursor: pointer;
}

.ytTitle {
  font-family: "gsb";
  font-weight: 400;
  font-size: 14px;
}
