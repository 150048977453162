@import url(https://cdn.syncfusion.com/ej2/material.css);
@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url(../../static/media/Gilroy-Medium.f9f832b0.ttf) format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url(../../static/media/Gilroy-Regular.b91b0127.ttf) format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url(../../static/media/Gilroy-SemiBold.67802926.ttf) format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url(../../static/media/Gilroy-Bold.b900325e.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  /* background-color: #17a2b8; */
  height: 100vh;
  color: #000;
}

.Login_bg {
  background-color: #f4f4f4;
  mix-blend-mode: normal;
}

.login {
  height: 100vh;
  border: none;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_txt {
  font-family: Gb;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.label_txt {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  color: #000000;
}

.form {
  border: 0.2px solid #000;
  padding: 50px;
  box-shadow: 2px 3px 4px 7px #fbfbfb;
  border-radius: 10px;
  box-shadow: 3px 4px 2px 1px lightgrey;
}

.submit_btn {
  border: none;
  width: 100%;
  height: 50px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: Gm;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #221e1f;
}

.login_input_fileds {
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Gm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #555c62;
  padding: 15px;
}

/* .login_input_fileds:focus {
  border: 1px solid #fcc314 !important;
  box-shadow: none !important;
} */

.password_wrapper {
  width: 100%;
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 5px;
  /* c */
}
.password_field:focus {
  outline: none;
}
.password_field {
  border: none;
  width: 94%;
  /* background-color: #f6f6f6;
  height: 40px; */
  font-family: Gm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  color: #555c62;
  border-radius: 5px;
}

.password_eye {
  color: #bbbbbb;
  cursor: pointer;
}

.remember_me_txt {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #555c62;
}

input[type="checkbox"] :checked {
  background-color: #fcc314;
  color: #fff;
}

@font-face {
  font-family: "Mr";
  src: local("Mr"), url(../../static/media/Montserrat-Regular.3cd78665.ttf) format("truetype");
}
@font-face {
  font-family: "Mm";
  src: local("Mm"), url(../../static/media/Montserrat-Medium.e2d60bc4.ttf) format("truetype");
}
@font-face {
  font-family: "Mb";
  src: local("Mb"), url(../../static/media/Montserrat-Bold.079ca05d.ttf) format("truetype");
}
@font-face {
  font-family: "Sb";
  src: local("Mb"), url(../../static/media/Montserrat-SemiBold.fa8441f3.ttf) format("truetype");
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url(../../static/media/Gilroy-Medium.f9f832b0.ttf) format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url(../../static/media/Gilroy-Regular.b91b0127.ttf) format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url(../../static/media/Gilroy-SemiBold.67802926.ttf) format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url(../../static/media/Gilroy-Bold.b900325e.ttf) format("truetype");
}

.sidenav {
  height: 100%;
  width: 220px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding: 14px;
  margin-top: 20px;
}

.main {
  margin-left: 220px;
  /* padding: 0px 10px; */
  background: #f4f4f4;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding: 20px;
  min-height: calc(100vh - 50px);
}

.bottom {
  margin-left: 220px;
  margin-top: 20px;
  margin-right: 20px;
  /* position: absolute; */
  /* padding: 20px; */
  height: 50px;
  bottom: 0px;
}

.copy_right_txt,
.cendrol_refappadmin {
  font-family: Gm;
  font-size: 14px;
  line-height: 16px;
  color: #0e0e0e;
}

.cendrol_refappadmin {
  color: #fcc314 !important;
}

.dropdown-menu a {
  display: block;
  text-decoration: none !important;
  padding: 6px;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  color: #969696;
  /* border-top: 1px solid #000; */
}

.dropdown-menu a:hover {
  color: #000000;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  /* .sidenav a {
    font-size: 18px;
  } */
}
.top_menu_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin_panel_viewmore_list {
  /* width: 80%; */
  cursor: pointer;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none !important;
  background: #f4f4f4;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #221e1f;
}

.viewmore {
  color: #0e0e0e;
  font-size: 20px;
}

tr:hover .viewmore {
  color: #fcc216;
}

tr:hover .admin_panel_viewmore_list {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  color: #221e1f;
}

.top_menu_search {
  border: none;
  width: 90%;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a9a9aa;
}

.top_menu_search:focus {
  outline: none;
  box-shadow: none;
}

.search_div {
  background: #fff;
  border-radius: 5px;
  padding: 0px 10px;
  width: 45%;
  display: flex;
  align-items: center;
}

.search_icons {
  color: #fcc314 !important;
  font-size: 18px !important;
}

.del_icons {
  color: red;
  font-size: 18px !important;
}

.add_btn {
  background-color: #ffffff;
  padding: 10px 20px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  /* line-height: 20px; */
  color: #221e1f;
  grid-gap: 10px;
  gap: 10px;
  height: 44px;
}

.add_btn:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.delete_btn {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  color: #000000;
}

.table_div {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
}

th {
  border-top: none !important;
  /* border-bottom: 1px solid #dee2e6; */
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffff;
  /* padding-bottom: 20px !important; */
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px;
  background: #111111 !important;
}

td {
  vertical-align: middle;
  border: none !important;
  padding: 16px !important;
  /* border-bottom: 1px solid #dee2e6 !important; */
}

tr {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #969696;
  text-align: center;
  border-bottom: 1px solid #dee2e6 !important;
}

tr:hover {
  color: #221e1f;
  background: #f4f4f4 !important;
  border-radius: 10px !important;
  cursor: pointer;
}

.value_row:hover {
  background-color: #f4f4f4;
}
.profile_pic {
  margin-right: 10px;
  margin-left: 10px;
  width: 50px;
}
.pending_btn {
  background: #fcc216;
  border-radius: 5px;
  border: none;
  color: #221e1f;
  padding: 5px 15px;
  width: 6rem;
  font-size: 14px;
}
.verified_btn {
  border: none;
  color: #221e1f;
  background: #16fcc5;
  width: 6rem;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.Onboarded,
.Interested,
.Dead,
.call_status,
.in_active {
  width: 100%;
  font-family: "Gm";
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #0e0e0e;
}

.call_status,
.in_active {
  font-family: "Gsb";
  pointer-events: none;
  border-radius: 3px;
  padding: 6px 12px;
}

.call_status {
  color: #3add5e;
  background: #effcf2;
}

.in_active {
  color: #fe3e3e;
  background: #ffecec;
}

.bde_high_priority,
.bde_medium_priority,
.bde_low_priority {
  border-radius: 2px;
  font-family: "Gsb";
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  pointer-events: none;
}

.bde_high_priority {
  color: #fe3e3e;
  background: #fff7f7;
}

.bde_medium_priority {
  color: #3add5e;
  background: #f7fef9;
}

.bde_low_priority {
  color: #fcc216;
  background: #fffaec;
}

.Onboarded {
  background: #3add5e;
  color: #ffffff;
}

.Interested {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  color: #ffffff;
}

.Dead {
  background: linear-gradient(99.99deg, #5c5c5c -3.74%, #0e0e0e 108.43%);
  color: #ffffff;
}

.ant-pagination-item-link svg {
  vertical-align: baseline !important;
}

.Onboarded:hover,
.Dead:hover,
.Interested:hover,
.call_status:hover {
  color: #ffffff;
}

.profile_details span {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.profile_details p {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #7e7e7e;
}

.side_menu_dashboardbtn {
  background: #f4f4f4;
  cursor: pointer;
  /* justify-content: space-evenly;
  display: flex;
  align-items: center; */
  padding: 15px;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  color: #969696;
}

.side_menu_dashboardbtn:hover {
  color: #221e1f;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.side_menu_dashboardbtn1 {
  background: #f4f4f4;
  cursor: pointer;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  color: #969696;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-decoration: none !important;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.side_nav_icons {
  color: #969696;
}

/* .side_menu_dashboardbtn1 .side_nav_icons:hover {
  color: #130f26 !important;
} */

.side_menu_dashboardbtn1:hover {
  color: #221e1f !important;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.active {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  color: #221e1f !important;
}

.side_menu_partnersbtn {
  cursor: pointer;
  background: #f4f4f4;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #969696;
  width: 100%;
  font-size: 14px;
  box-shadow: none;
}
.side_menu_partnersbtn:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}
.side_menu_partnersbtn:focus {
  transition: linear;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  color: #000;
  border: none;
  outline: none;
  box-shadow: none;
}
.side_menu_btn {
  background: #f4f4f4;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #969696;
  font-size: 14px;
}
.profile_pic_sidenav {
  width: 50px;
}
.dropdown-menu {
  width: 100%;
}
.content {
  /* background: #fff; */
  margin-top: 25px;
  padding: 20px;
  width: 100%;
  display: none;
}
.active-content {
  display: block;
}

.add_hot_lead_label {
  font-family: Gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #221e1f;
}

/* Add Referral */
.add_hot_lead_content {
  background: #f4f4f4;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.input-group_add_ref_partner {
  position: relative;
  /* width: 300px; */
}

.input-group_add_ref_partner .input-area_add_ref_partner {
  outline: none;
  padding-bottom: 3px !important;
  /* border: 2px solid #dadce0; */
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #969696;
  padding: 14px;
  font-size: 20px;
  font-family: "Gb";
  width: 100%;
  padding-left: 0px;
  background: #f4f4f4;
  /* border-radius: 5px; */
}

.input-group_add_ref_partner
  .input-area_add_ref_partner:valid
  + .label_add_ref_partner {
  top: -8px;
  padding: 0 3px;
  font-size: 10px;
  color: #8d8d8d;
}

.input-group_add_ref_partner .input-area_add_ref_partner:focus {
  border: none;
  border-bottom: 1px solid #000;
}

.input-group_add_ref_partner .label_add_ref_partner {
  color: #8d8d8d;
  position: absolute;
  top: 20px;
  font-size: 14px;
  font-family: "Gb";
  left: 0px;
  background: #f4f4f4;
  transition: 0.2s ease-in-out;
}

.input-group_add_ref_partner
  .input-area_add_ref_partner:focus
  + .label_add_ref_partner {
  top: -8px;
  padding: 0 3px;
  font-size: 14px;
  color: #969696;
  font-family: "Gb";
}

.btn_add_hot_lead {
  background: linear-gradient(162.29deg, #ffe185 -6.17%, #f7ba00 105.49%);
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-size: 18px;
  font-family: "Gsb";
  text-align: center;
  color: #000000;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
}

.btn_add_hot_lead span {
  align-self: center;
}

.btn_add_hot_lead:hover {
  box-shadow: 0px 11px 21px -3px rgba(0, 0, 0, 0.15) !important;
}

.popup_icon_center,
.popup_text_center {
  flex-direction: row;
  text-align: center;
}

/* add bank details */
.popup_addbankdetails_icon {
  color: #3add5e !important;
  font-size: 50px !important;
}
/* Add Bank Details PopUp End */

.popup_unlinkbankdetails_icon {
  color: #ff3535 !important;
  font-size: 50px !important;
}

.popup_addbankdetails_text {
  font-family: "Gsb";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.popup_btn_unlinkaccount,
.popup_btn_linkaccount {
  color: #ffffff;
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 5px;
  font-family: "Gsb";
  width: 80%;
  padding: 10px;
  border: none;
  font-size: 13px;
  line-height: 15px;
}
.popup_btn_linkaccount {
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
}
.popup_btn_close {
  color: #221e1f;
  background: #f4f4f4;
  border-radius: 5px;
  font-family: "Gsb";
  width: 80%;
  padding: 10px;
  border: none;
  font-size: 13px;
  line-height: 15px;
}
/* For partner details */
.verify_icons {
  color: #3add5e;
}
.not_verify_icons {
  color: #ff3535;
}
.verify_btn {
  display: flex;
  background-color: #fff;
  border-radius: 5px;
}
.verify_btn:focus {
  outline: none;
  border: none;
}
.partner_details_edit_sec {
  margin-top: 25px;
}

.admin_type_heading {
  text-align: left;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #969696;
  padding-bottom: 7px;
}

.admin_type_value,
.head_txt {
  text-align: left;
  font-family: Gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #221e1f;
}

.whats_app_div {
  background: #f4f4f4;
  border-radius: 2px;
  padding: 8px 10px;
  height: 100%;
}

.admin_patrner_personal_info {
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
}
.admin_partner_det_input {
  width: 100%;
  height: 30px;
  /* border-radius: 5px; */
}
.contact_info_ {
  line-height: 0rem;
}

.persqft {
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #221e1f;
}

.contact_info_edit {
  /* line-height: 2.5rem; */
}
.kyc_detail_title {
  font-size: 12px;
}
.download_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 6px 10px;
  font-size: 14px;
}

.partner_back_btn {
  text-decoration: none;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.partner_back_btn:hover {
  text-decoration: none;
  color: #000000;
}
.refered_lead_btn {
  background-color: #fff;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #000000 !important;
}
.edit_icon {
  cursor: pointer;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  background: none;
  /* identical to box height */

  color: #fcc314;
}

/* Lead details listing */
.admin_lead_package_btn1 {
  background: linear-gradient(
    108.2deg,
    #da9917 -14.5%,
    #ffff90 66.64%,
    #ecbf26 109.69%
  );
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 88%;
  font-size: 14px;
}
.admin_lead_package_btn2 {
  /* background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%); */
  background: linear-gradient(
    106.75deg,
    #c6c6c6 -8.91%,
    #f0f0f0 58.62%,
    #a5a5a5 124.71%
  );

  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 88%;
  font-size: 14px;
}
.file {
  width: 100%;
}
.pending_lead_btn {
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 5px;
  border: none;
  padding: 8px 24px;
  color: #fff;
}
.credited_lead_btn {
  color: #fff;
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
  border-radius: 5px;
  border: none;
  padding: 8px 24px;
  margin-right: 10px;
}
/* steper css for progress bar */

.stepper-wrapper {
  font-family: Arial;
  padding-top: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  /* border-bottom: 2px solid #ccc; */
  width: 100%;
  top: 19px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}
.Disabledclass {
  pointer-events: none;
}
.stepper-item.active {
  font-weight: bold;
}
.stepper-wrapper .stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 5px dotted #4bb543;
  width: 100%;
  top: 19px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.content1 {
  display: none;
}
.active-content1 {
  display: block;
}
.fsc_code {
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 10px;
  width: 60%;
}
.referedleads_count {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 6px 16px;
  border-radius: 5px;
}
.error {
  color: #dc3545;
}

.label_text,
.label_select {
  font-family: "Gm";
  font-size: 14px;
  line-height: 15px;
  color: #0e0e0e;
  padding-bottom: 8px;
}

.bluck_input,
.bluck_select {
  padding: 10px !important;
  border-color: #f0f0f0;
  font-size: 14px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.bluck_input:focus,
.bluck_select:focus {
  box-shadow: none !important;
  border-color: #fcc216 !important;
}

h3 {
  font-family: Gsb;
  font-size: 26px;
}

/* input[type="email"] {
  text-transform: lowercase;
} */

/* Sunil for CS & IS */

/* Channel sales */
.CS_head_txt {
  font-family: Gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
}

.search_icon {
  color: #000000 !important;
  font-size: 20px !important;
}

.select_dropdown_assign_to {
  border-radius: 5px;
  background: #f4f4f4;
  border: none;
  font-family: Gm;
  font-size: 14px;
  color: #221e1f;
  cursor: pointer;
}

tr:hover .select_dropdown_assign_to {
  background: #ffffff !important;
}

tr:hover .select_dropdown_assign_to:hover {
  background: linear-gradient(
    162.29deg,
    #ffe185 14.78%,
    #f7ba00 95.75%
  ) !important;
  border: none;
  outline: none !important;
}

.select_dropdown_assign_to:focus {
  outline: none !important;
}

tr:hover .select_dropdown_assign_to option:checked {
  background: linear-gradient(
    162.29deg,
    #ffe185 14.78%,
    #f7ba00 95.75%
  ) !important;
  outline: none !important;
}

/* tr:hover .select_dropdown_assign_to :checked {
  background: linear-gradient(
    162.29deg,
    #ffe185 14.78%,
    #f7ba00 95.75%
  ) !important;
} */

.head_txt {
  color: #000000;
  padding-bottom: 14px;
  font-size: 20px !important;
  border-bottom: 1px solid #f2f2f2;
}

.head_txt_margin {
  margin-bottom: 1em;
}

.head_txt p {
  margin-bottom: 0em;
}

.edit_txt_btn {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fcc314;
}

.tabs_start {
  grid-gap: 5%;
  gap: 5%;
  grid-gap: 5%;
  border-bottom: 2px solid #d6d6d6;
}

.tab_lead_data_start {
  padding: 0px 0px;
  width: 21%;
}

.tab_lead_data {
  border: none;
  outline: none;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #969696;
  background: none;
  width: 100%;
}

.active_tab_lead_data {
  color: #000000;
  width: 100%;
  border-bottom: 3px solid #0f0f0f;
  padding-bottom: 20px;
}

.lead-tab-content {
  width: 100%;
  display: none;
}

.lead-tab-active-content {
  display: block;
  position: relative;
}

.btn_add_comments_inc {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 5px;
}

.leads_comment_content {
  padding: 10px;
}

.doc_view {
  width: 100%;
  border: 2px dashed #969696;
  border-radius: 10px;
  padding: 10px;
}

.btn_lead_comments {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #000000;
  padding: 10px 20px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 15px;
  gap: 15px;
}

.btn_lead_comments span {
  align-self: center;
}

.search_icons_comments_lead {
  color: #221e1f !important;
  font-size: 18px !important;
}

.label_txt_mc {
  padding-bottom: 5px;
  font-family: gm;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* identical to box height */
  color: #000000;
}

.input_view_txt_mc {
  background: #f4f4f4;
  border-radius: 2px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  font-family: gsb;
  align-items: center;
  font-size: 16px;
  color: #221e1f;
  outline: none;
}

.td_check {
  padding: 8px 10px !important;
}

/* Side navbar */
.side_nav_icons_active {
  color: #000;
}
.side_menu_dashboardbtn {
  /* background: #f4f4f4; */
  cursor: pointer;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  font-family: gb;
  font-size: 14px;
  text-decoration: none;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}
.side_menu_dashboardbtn:hover .side_nav_icons {
  color: #000;
  text-decoration: none;
}
.side_menu_dashboardbtn:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  color: #000;
  text-decoration: none;
}

.drag_txt_csv {
  font-family: Gm;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #221e1f;
}

.filter_input_head_txt,
.lable_dropdown_options {
  font-family: Gm !important;
  font-size: 14px !important;
  line-height: 14px !important;
  text-align: center;
  color: #0e0e0e !important;
}

.to_txt {
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #969696;
  padding: 10px;
}

.lable_dropdown_options {
  color: #000000;
}

.total_count_txt {
  font-family: "Gm";
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #fcc216;
}

.custom_file_input {
  display: none;
}

.btn_tran_prof {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 10px;
}

.btn_tran_prof a {
  color: #000000 !important;
  text-decoration: none !important;
}

.btn_tran_prof a img {
  height: 15px;
}

.field_input_txt {
  font-family: Gm;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #0e0e0e;
  padding: 5px 14px 5px 14px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  height: 40px;
}

.field_input_txt:focus,
.field_input_txt:hover {
  outline: none !important;
  border: 1px solid #fcc216 !important;
}

.btn_resetall,
.btn_apply_filter {
  background: #f2f2f2;
  width: 100%;
  border-radius: 3px;
  font-family: Gm;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  border: none;
  outline: none;
  padding: 14px;
}

.btn_apply_filter {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.btn_apply_filter span {
  align-self: center;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  background: #ffffff;
  height: 20px;
  width: 20px;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  border-radius: 2px;
}

input[type="checkbox"]:after {
  content: " ";
  background-color: #fcc216;
  color: #000000;
  height: 20px;
  width: 20px;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  padding: 3px 3px 0px 4px;
}

.no_data_alert {
  font-size: 35px !important;
  color: #ff3535;
}
.no_data_alert_leaderboard {
  font-size: 30px !important;
  color: #ff3535;
}
.rs-picker-daterange-menu {
  z-index: 10000000000 !important; /*added a random number*/
}
.rs-picker-toggle-wrapper {
  width: 100%;
}
.rs-picker-toggle-placeholder {
  float: left;
  font-family: Gm;
  font-size: 16px;
  font-weight: 400;
}
/* .rs-icon {
  display: none;
} */

.rs-btn-link {
  background: #f4f4f4 !important;
  color: #221e1f !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  text-decoration: none !important;
}

a:active,
a:focus {
  color: #221e1f !important;
}

.rs-btn-link:hover {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
}

.rs-picker-toggle {
  height: 40px;
  border-radius: 3px !important;
}

.rs-picker-toggle-active,
.rs-picker-toggle:hover {
  border: 1px solid #fcc216 !important;
  box-shadow: none !important;
}

.rs-picker-toggle-value,
.rs-picker-toggle-clean {
  color: #000000 !important;
}

.date_range-picker .rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: #f4f4f4 !important;
}

.date_range-picker .rs-picker-toggle-caret {
  color: #fcc216 !important;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 11px !important;
}

.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 11px;
}

.rs-picker-toggle-textbox {
  padding-left: 50px !important;
}

.follow_up_filter .rs-picker-toggle {
  border: none !important;
  height: 44px;
}

.follow_up_filter .rs-picker-toggle-active,
.rs-picker-toggle:hover {
  border: 1px solid #fcc216 !important;
  box-shadow: none !important;
}

.follow_up_filter .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 12px !important;
  padding-bottom: 7px;
}

.follow_up_filter .rs-icon {
  width: 15px !important;
}

.follow_up_filter svg {
  top: 11px !important;
}

/* #emp_ID {
  text-transform: uppercase;
} */

.report_table,
.react-bootstrap-table .scroll_table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  height: 80vh;
  border-radius: 5px;
}

.react-bootstrap-table {
  height: 80vh;
}

.react-bootstrap-table table {
  overflow: auto !important;
  table-layout: auto !important;
  white-space: nowrap;
}

.react-bootstrap-table-pagination {
  padding-top: 15px;
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.table-responsive .react-bootstrap-table {
  width: 100% !important;
}

/* .react-bootstrap-table .table {
  width: 100% !important;
} */

/* .table-bordered > :not(caption) > * > * {
  border-width: 0px !important;
  box-shadow: none !important;
} */

.table-bordered td,
.table-bordered th {
  border: none !important;
}

.report_table tbody {
  display: table;
  width: 100%;
}

/* Dash Board */
.dashboard_head_txt {
  font-family: Gsb;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.dashboard_user_count {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 32%;
}

.dashboard_user_icon {
  background: linear-gradient(
    162.29deg,
    rgba(255, 225, 133, 0.2) -6.85%,
    rgba(247, 186, 0, 0.2) 100.08%
  );
  border-radius: 4.28571px;
  padding: 10px;
}

.dashboard_total_count_no {
  font-family: Gb;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.dashboard_total_count_sub_txt {
  font-family: Gm;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.pie_graph_head_txt {
  font-family: Gsb;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}

.pie_graph_dashboard {
  background: #ffffff;
  border-radius: 10px;
  width: 49%;
}

.pie_graph_dashboard tr {
  /* background: #f4f4f4; */
  border-radius: 3.19226px !important;
  font-family: Gsb;
  font-size: 14px;
  /* line-height: 14px; */
  color: #000000;
}

.dashboard_leaderboard_net_earning {
  font-family: "Gb";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  /* Green */
  float: left;
  color: #3add5e;
}

.dashboard_total_earnings_txt {
  font-family: "Gsb";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  float: left;
}

.dashboard_leaderboard_projects {
  border: 1px dashed #969696;
  border-radius: 2.55381px;
  padding: 6px;
}

.dashboard_line_graph {
  background: #ffffff;
  border-radius: 10px;
}

.text-color-class {
  font-family: Gm;
  font-size: 14px;
  line-height: 14px;
  color: #221e1f;
}

.recharts-legend-item {
  margin-bottom: 10px !important;
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgb(244, 244, 244) !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.select-form {
  font-family: "Gm";
  font-size: 15px;
  line-height: 15px;
  color: #221e1f;
  height: 100%;
}

.meeting_link {
  display: flex;
  background: #fff8e3;
  border-radius: 2px;
}

.meeting_link p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 223px;
  margin-bottom: 0em;
}

.btn_join_meeting {
  width: 100%;
  height: 100%;
  background: linear-gradient(93.32deg, #ffe185 -1.74%, #f7ba00 103.94%);
  border-radius: 2px;
  font-family: "Gm";
  font-size: 16px;
  text-align: center;
  color: #000000 !important;
  padding: 10px;
  text-decoration: none !important;
}

.btn_meeting_done,
.btn_not_intersted,
.btn_deal_lost {
  border-radius: 4px;
  font-family: "Gsb";
  font-size: 15px;
  line-height: 15px;
  color: #000000;
  padding: 12px 20px;
}

.btn_meeting_done img {
  width: 22px !important;
  height: 22px !important;
}

.btn_meeting_done {
  background: linear-gradient(93.05deg, #ffe185 -4.83%, #f7ba00 107.65%);
}

.btn_deal_lost {
  color: #fe3e3e;
  border: 1px solid #fe3e3e;
  box-sizing: border-box;
  width: 150px;
}

.btn_not_intersted {
  background: #f4f4f4;
  border: 0.8px solid #0e0e0e;
  box-sizing: border-box;
}

.pop_up_meeting_txt {
  font-family: "Gsb";
  font-size: 19px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.btn_meeting_cancel,
.btn_meeting_notIntersetd,
.btn_popup_meeting_done {
  border-radius: 3px;
  font-family: "Gsb";
  font-size: 16px;
  line-height: 16px;
  padding: 10px;
  width: 80%;
  height: 40px;
}

.btn_meeting_cancel {
  background: #f4f4f4;
  color: #221e1f;
}

.btn_popup_meeting_done {
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
  color: #ffffff;
}

.btn_meeting_notIntersetd {
  background: linear-gradient(95.65deg, #ff3535 -12.3%, #af1818 110.71%);
  color: #ffffff;
}

.popup_meet .modal-sm {
  max-width: 400px !important;
}

.form_head_txt {
  font-family: "Gsb";
  font-size: 20px;
  line-height: 23px;
  color: #0e0e0e;
}

/* Rounded Dots  */
.one_month,
.three_month,
.six_month,
.follow_up,
.junk,
.hot_lead {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.one_month {
  background: #3add5e;
}

.three_month {
  background: #fcc314;
}

.six_month {
  background: #d150ff;
}

.follow_up {
  background: #506cff;
}

.junk {
  background: #221e1f;
}

.hot_lead {
  background: linear-gradient(154.95deg, #ffca2c 7.32%, #ff6618 96.17%);
}

.high_priority,
.low_priority,
.medium_priority {
  border-radius: 3px;
  font-family: Gm;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px;
  width: 88%;
}

.high_priority {
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
}

.low_priority {
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
}

.medium_priority {
  background: linear-gradient(360deg, #ff8008 6%, #ffc837 112.5%);
}

.call_not_connected,
.call_follow_up,
.call_dnr,
.call_intersted,
.call_meeting,
.call_qualified,
.call_not_intersted,
.call_meet_done,
.call_deal_lost,
.call_book_amount,
.call_quotation,
.call_dummy,
.call_out_of_scope {
  font-family: "Gsb";
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
}

.call_not_connected {
  background: #fe3e3e;
}

.call_follow_up {
  background: #fcc216;
}

.call_dnr {
  background: #b216fc;
}

.call_intersted {
  background: #333bff;
}

.call_meeting {
  background: #3add5e;
}

.call_qualified {
  background: #fc7716;
}

.call_not_intersted {
  background: #0e0e0e;
}

.call_meet_done {
  background: #ffa113;
}

.call_deal_lost {
  background: #0e0e0e;
}

.call_book_amount {
  background: #3add5e;
}

.call_quotation {
  background: #3ad3dd;
}

.call_dummy {
  background: #b6b6b6;
}

.call_out_of_scope {
  background: #7eaaaa;
}

.call_not_connected:hover,
.call_follow_up:hover,
.call_dnr:hover,
.call_intersted:hover,
.call_meeting:hover,
.call_qualified:hover,
.call_not_intersted:hover,
.call_meet_done:hover,
.call_deal_lost:hover,
.call_book_amount:hover,
.call_quotation:hover,
.call_dummy:hover,
.call_out_of_scope:hover {
  color: #ffffff;
}

/* antd */
.ant-pagination-item:hover {
  border: none !important;
  outline: none !important;
  border-color: none !important;
}

.ant-pagination-item-active {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
  border: none !important;
}
/* #fcc216 */

.ant-pagination-options-quick-jumper input:hover,
.ant-pagination-options-quick-jumper input:focus {
  border-color: #fcc216 !important;
  box-shadow: none !important;
}

/* react-bootstrap-table */
.pagination {
  float: right;
}

.page-link,
.page-link:hover {
  color: #000000;
  font-family: "Gm";
  font-size: 15px;
}

.page-item.active .page-link {
  border-color: #fcc216;
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
  color: #000000;
}

.page-link:focus {
  /* outline: none; */
  box-shadow: none;
}

.react-bootstrap-table-pagination-total {
  padding-left: 5px;
  color: #000000;
  font-family: "Gm";
  font-size: 15px;
}

/* ADD Task BDE */
.add_task_btn {
  padding: 12px 20px;
  background: linear-gradient(93.05deg, #ffe185 -4.83%, #f7ba00 107.65%);
  border-radius: 3px;
  font-family: "Gsb";
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #000000;
}

.add_task_icon {
  color: #000000 !important;
  font-size: 18px !important;
}

/* sales pipeline */
.panel {
  height: calc(100% - 50px);
}

.fun_wrap {
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
}

.fun {
  display: table;
  padding-right: 20px;
}

.fun_wrap,
.fun {
  height: 100%;
  position: relative;
}

.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: 0.2em;
  padding: 0.5em 0.8em 0.5em 0.5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: 0.5em;
  margin-right: 0.5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.upload_document_txt {
  font-family: "Gb";
  /* font-size: 18px;
  line-height: 21px; */
  color: #221e1f;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.browse_btn {
  background: #fffcf3;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Gm";
  font-size: 12px;
  line-height: 14px;
  padding: 10px;
  text-align: center;
  color: #0e0e0e;
}

.btn_upload_document {
  font-family: "Gsb";
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #221e1f;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.btn_upload_document span {
  align-self: center;
}

.dropzone_img {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: #bdbdbd;
  margin-bottom: 20px;
  height: 40vh;
  display: flex;
  align-items: center;
  background: #fffefa;
  border: 1.5px dashed #969696;
  border-radius: 5px;
}

.info_icon {
  font-size: 15px !important;
  color: #fcc216;
  outline: none;
}

.btn_download_pdf {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  grid-gap: 10px;
  gap: 10px;
  line-height: 16px;
  color: #221e1f;
  padding: 10px;
  display: flex;
  cursor: pointer;
}

.dropdown_action {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  font-family: "Gm";
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding: 10px 15px;
}

.dropdown_action:focus {
  outline: none;
  box-shadow: none !important;
}

.dropdown_action::after {
  margin-left: 1.255em;
}

.action .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09) !important;
  border-radius: 2px;
  border: none;
  top: 5px !important;
}

.action .dropdown-item {
  font-family: "Gm";
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}

/* Performance Graphs css */
.isperformance_graph_dashboard {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
}

.App {
  font-family: sans-serif;
}

ul {
  margin: 0;
}

.dropzone {
  text-align: center;
  padding: 20px;

  color: #bdbdbd;
  margin-bottom: 20px;
  height: 60vh;
  display: flex;
  align-items: center;

  background: #ffffff;
  border: 1px dashed #908f8f;
  border-radius: 10px;
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url(../../static/media/Gilroy-Medium.f9f832b0.ttf) format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url(../../static/media/Gilroy-Regular.b91b0127.ttf) format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url(../../static/media/Gilroy-SemiBold.67802926.ttf) format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url(../../static/media/Gilroy-Bold.b900325e.ttf) format("truetype");
}
.first_icon {
  width: 40px;
}

.first_icon_bg,
.second_icon_bg,
.third_icon_bg,
.fourth_icon_bg,
.fifth_icon_bg {
  padding: 10px;
  border-radius: 4.28571px;
}

.first_icon_bg {
  background: rgba(58, 221, 211, 0.08);
}

.second_icon_bg {
  background: #effcf2;
}

.third_icon_bg {
  background: rgba(80, 255, 255, 0.08);
}

.fourth_icon_bg {
  background: rgba(255, 217, 104, 0.08);
}

.fifth_icon_bg {
  background: rgba(255, 153, 96, 0.08);
}

.bg-light {
  background: #ffffff !important;
  border-radius: 4px;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  font-size: 12px;
}

.bg-light span {
  font-family: "Gb";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.bg-light p {
  font-family: "Gm";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 15px; */
  color: #000000;
}

.sec_icon_bg {
  background: #effcf2;
  padding: 5px;
  border-radius: 4.28571px;
}

.total_calls {
  color: #3add5e;
  padding: 5px;
  font-family: gsb;
  background: #effcf2;
  border-radius: 2px;
}

p {
  margin-bottom: 0px;
}

#setDiv {
  margin: 20px 15px 10px 225px;
  padding: 25px 25px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

#setHeader {
  color: black;
  font-family: "gsb";
  margin-bottom: 0px;
}

.contentDiv {
  background: #ffffff;
  border-radius: 2px;
  padding: 15px 25px;
  margin-top: 15px;
}

.contHead {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.addBtn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  color: black;
  font-family: "gsb";
  padding: 5px 15px;
  font-size: 12px;
}

.editBtn {
  background: #f5f5f5;
  border-radius: 2px;
  color: black;
  font-family: "gsb";
  padding: 5px 15px;
  font-size: 12px;
  margin-right: 15px;
}

.cellDiv {
  height: -webkit-max-content;
  height: max-content;
  width: auto;
  margin: 5px;
  border-radius: 3px;
}

.viewContentDiv {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  margin-top: 15px;
  border-radius: 3px;
}

.imgDiv {
  width: 247px;
  height: 177px;
  background-color: grey;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.details {
  height: 35%;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rank {
  background-color: white;
  padding: 9px 10px;
  border-radius: 3px;
  margin-right: 15px;
  color: black;
  font-family: "gsb";
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projName {
  font-size: 17px;
  color: #0e0e0e;
  font-family: "gsb";
}

.viewDets {
  font-size: 13px;
  color: #fcc216;
  margin-top: 2px;
  font-family: "gm";
}

input,
select,
textarea {
  color: black;
}

.rankInput {
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 3px;
  color: #0e0e0e;
  text-align: center;
  margin-right: 15px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  font-family: "gsb";
}

.rankInput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.TabSec {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.tabs {
  margin: 0;
  min-width: 165px;
  font-family: "gm";
  color: #cdcdcd;
  border-bottom: 1.5px solid #cdcdcd;
  text-align: center;
  cursor: pointer;
}
.activeTab {
  margin: 0;
  min-width: 165px;
  font-family: "gm";
  color: black;
  border-bottom: 1px solid black;
  text-align: center;
  cursor: pointer;
}

.popupHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.popupBox {
  padding: 25px;
  border: none;
  margin-bottom: 250px;
}

.popupBody {
  padding: 0;
}

.popupLabel {
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: "gm";
  font-size: 13px;
  font-weight: 400;
  color: black;
}

.popupHeadText {
  font-family: "gsb";
  font-size: 20px;
  color: black;
}

.popupInput {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  height: 35px;
  padding: 5px;
  color: black;
  font-family: "gm";
}

.popupInput:focus-visible {
  border-color: #fcc216 !important;
}

.popupSubmit {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 3px;
  width: 100%;
  height: 42px;
  margin-top: 25px;
  font-family: "gm";
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.popupClose {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px;
}

.tyThumbnail {
  width: 197px;
  height: 116px;
}

.ytTitleDiv {
  max-width: 195px;
  display: flex;
  padding: 0px 5px;
}

.ytRedirect:hover {
  cursor: pointer;
}

.ytTitle {
  font-family: "gsb";
  font-weight: 400;
  font-size: 14px;
}

#SwitchDiv {
  width: 45px;
  height: 25px;
}

#innerDiv {
  background: #969696;
  width: 45px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 2.5px;
}

#SwitchActive {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  width: 45px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 2.5px;
}

#toggleBubble {
  background: #ffffff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.cellRowDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  background-color: #f6f6f6;
  padding: 10px 15px;
  border-radius: 3px;
}

.rowInfoText {
  color: black;
  font-family: "gsb";
  font-weight: 400;
  font-size: 15px;
  margin-top: 0px;
  text-align: center;
}

.rowRanknProjName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowLabels {
  font-family: "gm";
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}

.projRowRank {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.viewDetsRowBtn {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.projectValue {
  width: 115px;
  height: 32px;
  background: #3add5e;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.gripper {
  width: 6px;
  height: 14px;
  margin-right: 15px;
}

.popupFormRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.marginR15 {
  margin-right: 15px;
}

.popupFormDiv {
  padding-right: 1px;
  border-right: 1px solid #f2f2f2;
}

.popupImgDiv {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.popupBiSectionDiv {
  display: flex;
  flex-direction: row;
}

.popupSubmitSm {
  width: 154px;
  height: 42px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  font-family: "gm";
  color: black;
  font-size: 14px;
  margin-right: 15px;
}

.popupDeleteSm {
  width: 154px;
  height: 42px;
  background: #cfcfcf;
  border-radius: 2px;
  font-family: "gm";
  color: black;
  font-size: 14px;
  margin-right: 15px;
}

.checkBxContainer {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100px;
}

.settingCheckbox {
  margin-right: 5px;
}

.popupInputLg {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  height: 50px;
  width: 375px;
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 5px;
}

.imgPrev {
  width: 128px;
  height: 84px;
  border-radius: 2px;
  object-fit: cover;
  margin-top: 15px;
  margin-right: 15px;
  z-index: 1;
}

.hoverDel {
  display: none;
}

.imgParent:hover .hoverDel {
  display: block;
  position: absolute;
  width: 128px;
  height: 84px;
  border-radius: 2px;
  object-fit: cover;
  margin-top: 15px;
  margin-right: 15px;
  image-rendering: -webkit-optimize-contrast;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 128px;
  height: 84px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 128px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #96969696;
  border-radius: 3px;
  margin-top: 15px;
  background-color: #fffdf9;
}

.addimgbtn {
  color: #f7ba00;
  background-color: transparent;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.addProjText {
  font-family: "gsb";
  font-size: 13px;
  color: black;
}

.tabsNbtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0px;
  align-items: center;
}

.catTabs {
  display: flex;
  flex-direction: row;
  background: #f5f5f5;
  border-radius: 2px;
  width: -webkit-max-content;
  width: max-content;
  padding: 5px;
}

.catCells {
  width: 85px;
  height: 32px;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: black;
  margin-top: 0px;
  font-family: "gm";
  cursor: pointer;
}

.popupRow {
  display: flex;
  flex-direction: row;
}

.popupTableHead {
  padding: 10px 0px;
  border-bottom: 1px solid #cfcfcf;
}

.popupDivL {
  width: 70%;
  margin-right: 15px;
  margin-top: 15px;
}

.popupDivR {
  width: 30%;
  margin-top: 15px;
}

.catDelete {
  background: #fff0f0;
  border-radius: 3px;
  font-family: "gm";
  font-size: 14px;
  padding: 5px 15px;
  color: #fe3e3e;
  margin-right: 15px;
}

.catAdd {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 3px;
}


.partnerDiv {
  width: 336.89px;
  height: 87.7px;
  background: #f4f4f4;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 25px;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.imgNdets {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.partnerImg {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: grey;
  margin-right: 15px;
  background-size: cover;
}

@font-face {
  font-family: "Mr";
  src: local("Mr"),
    url(../../static/media/Montserrat-Regular.3cd78665.ttf) format("truetype");
}
@font-face {
  font-family: "Mm";
  src: local("Mm"), url(../../static/media/Montserrat-Medium.e2d60bc4.ttf) format("truetype");
}
@font-face {
  font-family: "Mb";
  src: local("Mb"), url(../../static/media/Montserrat-Bold.079ca05d.ttf) format("truetype");
}
@font-face {
  font-family: "Sb";
  src: local("Mb"),
    url(../../static/media/Montserrat-SemiBold.fa8441f3.ttf) format("truetype");
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url(../../static/media/Gilroy-Medium.f9f832b0.ttf) format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url(../../static/media/Gilroy-Regular.b91b0127.ttf) format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url(../../static/media/Gilroy-SemiBold.67802926.ttf) format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url(../../static/media/Gilroy-Bold.b900325e.ttf) format("truetype");
}
.document_main_div {
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}
.docs_edit_btn {
  width: 18px !important;
}
.package_box {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 16px;
}
.pack_txt {
  font-family: Gsb;
  color: #000;
}
.package_indicator {
  background: #fcc216;
  border-radius: 2px;
  color: black;
  padding: 2px 12px;
  font-size: 18px;
}
.pack_price {
  color: #969696;
  font-family: Gm;
  font-size: 12px;
}
.edit_pack {
  font-family: Gm;
  color: #fcc216;
  font-size: 12px;
  cursor: pointer;
}
.docs_box {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 8px;
}
.dwnd_btn {
  background: #ffffff;
  width: 100%;
  border-radius: 2px;
  font-family: Gsb;
  padding: 10px;
  font-size: 12px;
}
.pack_txt1 {
  font-family: Gsb;
  color: #000;
  font-size: 12px;
  height: 38px;
}
.download_ico {
  color: #fcc216;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #fcc216;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.remove_btn {
  background: rgba(254, 62, 62, 0.05);
  border-radius: 2px;
  width: 100%;
  color: #fe3e3e;
  font-family: Gm;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  border: none;
  outline: none;
  padding: 10px;
}
.delpackage_btn {
  color: #fe3e3e;
}
.popup-overlay1-content {
  margin: auto;
  padding: 10px;
  width: 40% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.save_changes_btn {
  width: 50% !important;
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
  border-radius: 3px;
  font-family: Gm;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  border: none;
  outline: none;
  padding: 14px;
}
.package_headings {
  font-family: Gsb;
}

@font-face {
  font-family: "Mr";
  src: local("Mr"),
    url(../../static/media/Montserrat-Regular.3cd78665.ttf) format("truetype");
}
@font-face {
  font-family: "Mm";
  src: local("Mm"), url(../../static/media/Montserrat-Medium.e2d60bc4.ttf) format("truetype");
}
@font-face {
  font-family: "Mb";
  src: local("Mb"), url(../../static/media/Montserrat-Bold.079ca05d.ttf) format("truetype");
}
@font-face {
  font-family: "Sb";
  src: local("Mb"),
    url(../../static/media/Montserrat-SemiBold.fa8441f3.ttf) format("truetype");
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url(../../static/media/Gilroy-Medium.f9f832b0.ttf) format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url(../../static/media/Gilroy-Regular.b91b0127.ttf) format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url(../../static/media/Gilroy-SemiBold.67802926.ttf) format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url(../../static/media/Gilroy-Bold.b900325e.ttf) format("truetype");
}
#bar {
  width: 98%;
  height: 5px;
  background-color: #f4f4f4;
  margin: 25px 5px;
  position: absolute;
}

#progressColor {
  height: 5px;
  background-color: #fcc216;
}

.stepper {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: white;
  margin-top: 15px;
  top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2.175px solid rgb(252, 194, 22);
  z-index: 1;
}

#parentContainer {
  margin-top: 10px;
  height: 90px;
  width: 95%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: -2;
}

.stepperLabel {
  position: absolute;
  margin-top: 65px;
  font-family: Gm;
  color: #000;
}

.tickImg {
  object-fit: cover;
  width: 30px;
  height: 30px;
  z-index: 1;
}
.content1 {
  display: none;
}
.active-content1 {
  display: block;
}
.input_fields_quote {
  padding: 15px;
  border: 0.75px solid #969696;
  border-radius: 3px;
  color: #969696;
  font-size: 14px;
  font-family: Gm;
  /* width: 100%; */
}
.input_fields_quote:focus {
  outline: none;
}
.submit_cont_btn {
  font-family: Gb;
  font-weight: 600;
  padding: 10px;
  background: linear-gradient(93.32deg, #ffe185 -1.74%, #f7ba00 103.94%);
  border-radius: 4px;
}
.add_new_btn {
  background: #fff;
  color: #000;
  font-family: Gm;
  cursor: pointer;
}
.add_icon {
  color: #000;
  background-color: #f7ba00;
  padding: 3px;
}
.floor_headings {
  font-size: 16px;
  color: #000;
  background: #fffcf1;
  border-radius: 3px;
  font-family: Gsb;
  padding: 8px;
}
.input_fields_quoteg {
  padding: 15px;
  border: 0.75px solid #969696;
  border-radius: 3px;
  color: #969696;
  font-size: 14px;
  width: 100%;
}
.input_fields_quoteg:focus {
  outline: none;
}
.border_line {
  border-top: 2px dashed #cfcfcf;
}
.pack_btn_parent {
  grid-gap: 10px;
  gap: 10px;
}
.package_btns_qg {
  cursor: pointer;
  padding: 20px;
  text-align: center;
  border: 2.5px dashed #969696;
  border-radius: 4px;
  height: 100px;
}
.Package_name {
  font-family: Gm;
}
.activeButton {
  border: 1.2px solid #fcc216;
}
.total_cost_div span {
  color: #000;
  font-family: Gm;
}
.total_cost_div p {
  color: #000;
  font-family: Gsb;
  font-size: 20px;
}
.labels_div {
  width: 90%;
  position: absolute;
  margin-left: -5rem;
}
/* css for final pdf generated quote */
.strip_top {
  background: radial-gradient(
      116.82% 1273.18% at -2.17% -68.16%,
      #696969 0%,
      #0e0e0e 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  height: 20px;
}
.company_address p {
  font-family: Gb;
  font-size: 16px;
  color: #000;
}
.company_address span {
  font-family: Gsb;
  font-size: 12px;
  color: #969696;
}
.customer_info {
  border-bottom: 1px solid #d7d7d7;
}
.customer_info p {
  color: #fcc216;
  font-family: Gsb;
}
.customer_content {
  font-family: Gsb;
  font-size: 12px;
}
.border_lineq {
  border-top: 2px dashed #e6e6e6;
}
.floor_details p {
  color: #000;
  font-family: Gsb;
  font-family: 15px;
}
.table_for_floor_info {
  width: 100%;
}
.floor_table {
  width: 100%;
}
thead .table_header th {
  background-color: #f7ba00 !important;
  color: #000;
  padding: 10px;
}
.payment_details_nschedule {
  color: #000;
  font-family: Gsb;
  font-size: 14px;
}
.original_color {
  color: #000 !important;
}
.area_details_label {
  font-family: Gm;
}
.floor_inc {
  border: 0.75px solid #969696;
  padding: 4px;
  margin-left: 10px;
}
.padding_for_table {
  padding: 10px;
}
.view_doc_btn {
  background: #f4f4f4;
  border-radius: 2px;
  padding: 10px;
}

@font-face {
  font-family: "Mr";
  src: local("Mr"),
    url(../../static/media/Montserrat-Regular.3cd78665.ttf) format("truetype");
}
@font-face {
  font-family: "Mm";
  src: local("Mm"), url(../../static/media/Montserrat-Medium.e2d60bc4.ttf) format("truetype");
}
@font-face {
  font-family: "Mb";
  src: local("Mb"), url(../../static/media/Montserrat-Bold.079ca05d.ttf) format("truetype");
}
@font-face {
  font-family: "Sb";
  src: local("Mb"),
    url(../../static/media/Montserrat-SemiBold.fa8441f3.ttf) format("truetype");
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url(../../static/media/Gilroy-Medium.f9f832b0.ttf) format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url(../../static/media/Gilroy-Regular.b91b0127.ttf) format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url(../../static/media/Gilroy-SemiBold.67802926.ttf) format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url(../../static/media/Gilroy-Bold.b900325e.ttf) format("truetype");
}
#bar {
  width: 97%;
  height: 5px;
  background-color: #f4f4f4;
  margin: 25px 5px;
  position: absolute;
}

#progressColor {
  height: 5px;
  background-color: #fcc216;
}

.stepper {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: white;
  margin-top: 15px;
  top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2.175px solid rgb(252, 194, 22);
  z-index: 1;
}

#parentContainer {
  margin-top: 10px;
  height: 90px;
  width: 93%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: -2;
}

.stepperLabel {
  position: absolute;
  margin-top: 65px;
  font-family: Gsb;
  color: #000;
}

.tickImg {
  object-fit: cover;
  width: 30px;
  height: 30px;
  z-index: 1;
}
.content1 {
  display: none;
}
.active-content1 {
  display: block;
}
.input_fields_quote {
  padding: 15px;
  border: 0.75px solid #969696;
  border-radius: 3px;
  color: #000;
  font-size: 13px;
  font-family: Gm;
  height: 48px;
  margin-top: 10px;
  width: 100%;
}
.input_fields_quote:focus {
  outline: none;
}
.submit_cont_btn {
  font-family: Gb;
  font-weight: 600;
  padding: 10px;
  background: linear-gradient(93.32deg, #ffe185 -1.74%, #f7ba00 103.94%);
  border-radius: 4px;
}
.delete_spec_btn {
  color: red;
  font-family: Gb;
  font-weight: 600;
  padding: 10px;
  background: #fff5f5;
  border-radius: 5px;
}
.add_new_btn {
  background: #fff;
  color: #000;
  font-family: Gm;
  cursor: pointer;
}
.add_icon {
  color: #000;
  background-color: #f7ba00;
  padding: 3px;
}
.floor_headings {
  font-size: 16px;
  color: #000;
  background: #fffcf1;
  border-radius: 3px;
  font-family: Gsb;
  padding: 8px;
}
.input_fields_quoteg {
  padding: 15px;
  border: 0.75px solid #969696;
  border-radius: 3px;
  font-family: "Gm";
  color: #969696;
  font-size: 14px;
  width: 100%;
}
.input_fields_quoteg:focus {
  outline: none;
}
.border_line {
  border-top: 2px dashed #cfcfcf;
}
.pack_btn_parent {
  grid-gap: 10px;
  gap: 10px;
}
.package_btns_qg {
  cursor: pointer;
  padding: 20px;
  text-align: center;
  border: 2.5px dashed #969696;
  border-radius: 4px;
  height: 100px;
}
.Package_name {
  font-family: Gm;
  color: #fcc216;
}
.activeButton {
  border: 1.2px solid #fcc216;
}
.total_cost_div span {
  color: #000;
  font-family: Gm;
}
.total_cost_div p {
  color: #000;
  font-family: Gsb;
  font-size: 20px;
}
.labels_div {
  width: 79%;
  font-family: Gsb;
  position: absolute;
  margin-left: 0rem;
  margin-top: 56px;
}
/* css for final pdf generated quote */
.strip_top {
  background: radial-gradient(
      116.82% 1273.18% at -2.17% -68.16%,
      #696969 0%,
      #0e0e0e 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  height: 20px;
}
.company_address p {
  font-family: Gb;
  font-size: 16px;
  color: #000;
}
.company_address span {
  font-family: Gsb;
  font-size: 12px;
  color: #969696;
}
.customer_info {
  border-bottom: 1px solid #d7d7d7;
}
.customer_info p {
  color: #fcc216;
  font-family: Gsb;
}
.customer_content {
  font-family: Gsb;
  font-size: 12px;
}
.border_lineq {
  border-top: 2px dashed #e6e6e6;
}
.floor_details p {
  color: #000;
  font-family: Gsb;
  font-family: 15px;
}
.table_for_floor_info {
  width: 100%;
}
.floor_table {
  width: 100%;
}
thead .table_header th {
  background-color: #f7ba00 !important;
  color: #000;
  padding: 10px;
}
.payment_details_nschedule {
  color: #000;
  font-family: Gsb;
  font-size: 14px;
}
.original_color {
  color: #000 !important;
}
.area_details_label {
  font-family: Gm;
}
.floor_inc {
  border: 0.75px solid #969696;
  padding: 4px;
  margin-left: 10px;
}
.padding_for_table {
  padding: 10px;
}
.view_doc_btn {
  background: #f4f4f4;
  border-radius: 2px;
  padding: 10px;
}
.floor_main {
  line-height: 1rem;
  font-family: gsb;
  /* border: 0.7px solid #d6d6d6; */
}
.floor_input {
  width: 100%;
  border: none;
  padding: 10px;
  padding-left: 10px;
}
.floor_input:focus {
  outline: none;
}
.G_head {
  font-family: Gsb;
  font-size: 17px;
  /* border: 0.75px solid #969696; */
  padding: 15px;
  /* margin-left: 10px; */
  background: #f1f1f1;
}
.site_details_heading_div {
  font-family: Gsb;
  padding: 15px;
  background: linear-gradient(99.16deg, #8a8a8a -16.08%, #0e0e0e 102.04%);
  border-radius: 1px 1px 0px 0px;
  color: #f1f1f1;
}
.site_details_main {
  background: #ffffff;
  border: 1px solid #e1e1e1;
}
.text-yellow {
  color: #fcc216;
  font-family: Gsb;
}

