@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Bold.ttf") format("truetype");
}
.first_icon {
  width: 40px;
}

.first_icon_bg,
.second_icon_bg,
.third_icon_bg,
.fourth_icon_bg,
.fifth_icon_bg {
  padding: 10px;
  border-radius: 4.28571px;
}

.first_icon_bg {
  background: rgba(58, 221, 211, 0.08);
}

.second_icon_bg {
  background: #effcf2;
}

.third_icon_bg {
  background: rgba(80, 255, 255, 0.08);
}

.fourth_icon_bg {
  background: rgba(255, 217, 104, 0.08);
}

.fifth_icon_bg {
  background: rgba(255, 153, 96, 0.08);
}

.bg-light {
  background: #ffffff !important;
  border-radius: 4px;
  width: 100%;
  gap: 10px;
  padding: 10px;
  font-size: 12px;
}

.bg-light span {
  font-family: "Gb";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.bg-light p {
  font-family: "Gm";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 15px; */
  color: #000000;
}

.sec_icon_bg {
  background: #effcf2;
  padding: 5px;
  border-radius: 4.28571px;
}

.total_calls {
  color: #3add5e;
  padding: 5px;
  font-family: gsb;
  background: #effcf2;
  border-radius: 2px;
}
