.partnerDiv {
  width: 336.89px;
  height: 87.7px;
  background: #f4f4f4;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 25px;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.imgNdets {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.partnerImg {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: grey;
  margin-right: 15px;
  background-size: cover;
}
