@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url("../../Fonts/Gilroy\ Font/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url("../../Fonts/Gilroy\ Font/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url("../../Fonts/Gilroy\ Font/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url("../../Fonts/Gilroy\ Font/Gilroy-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  /* background-color: #17a2b8; */
  height: 100vh;
  color: #000;
}

.Login_bg {
  background-color: #f4f4f4;
  mix-blend-mode: normal;
}

.login {
  height: 100vh;
  border: none;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_txt {
  font-family: Gb;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.label_txt {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  color: #000000;
}

.form {
  border: 0.2px solid #000;
  padding: 50px;
  box-shadow: 2px 3px 4px 7px #fbfbfb;
  border-radius: 10px;
  box-shadow: 3px 4px 2px 1px lightgrey;
}

.submit_btn {
  border: none;
  width: 100%;
  height: 50px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: Gm;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #221e1f;
}

.login_input_fileds {
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Gm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #555c62;
  padding: 15px;
}

/* .login_input_fileds:focus {
  border: 1px solid #fcc314 !important;
  box-shadow: none !important;
} */

.password_wrapper {
  width: 100%;
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 5px;
  /* c */
}
.password_field:focus {
  outline: none;
}
.password_field {
  border: none;
  width: 94%;
  /* background-color: #f6f6f6;
  height: 40px; */
  font-family: Gm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  color: #555c62;
  border-radius: 5px;
}

.password_eye {
  color: #bbbbbb;
  cursor: pointer;
}

.remember_me_txt {
  font-family: Gm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #555c62;
}

input[type="checkbox"] :checked {
  background-color: #fcc314;
  color: #fff;
}
