@font-face {
  font-family: "Mr";
  src: local("Mr"),
    url(../../../Fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Mm";
  src: local("Mm"), url(../../../Fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Mb";
  src: local("Mb"), url(../../../Fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Sb";
  src: local("Mb"),
    url(../../../Fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Bold.ttf") format("truetype");
}
#bar {
  width: 98%;
  height: 5px;
  background-color: #f4f4f4;
  margin: 25px 5px;
  position: absolute;
}

#progressColor {
  height: 5px;
  background-color: #fcc216;
}

.stepper {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: white;
  margin-top: 15px;
  top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2.175px solid rgb(252, 194, 22);
  z-index: 1;
}

#parentContainer {
  margin-top: 10px;
  height: 90px;
  width: 95%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: -2;
}

.stepperLabel {
  position: absolute;
  margin-top: 65px;
  font-family: Gm;
  color: #000;
}

.tickImg {
  object-fit: cover;
  width: 30px;
  height: 30px;
  z-index: 1;
}
.content1 {
  display: none;
}
.active-content1 {
  display: block;
}
.input_fields_quote {
  padding: 15px;
  border: 0.75px solid #969696;
  border-radius: 3px;
  color: #969696;
  font-size: 14px;
  font-family: Gm;
  /* width: 100%; */
}
.input_fields_quote:focus {
  outline: none;
}
.submit_cont_btn {
  font-family: Gb;
  font-weight: 600;
  padding: 10px;
  background: linear-gradient(93.32deg, #ffe185 -1.74%, #f7ba00 103.94%);
  border-radius: 4px;
}
.add_new_btn {
  background: #fff;
  color: #000;
  font-family: Gm;
  cursor: pointer;
}
.add_icon {
  color: #000;
  background-color: #f7ba00;
  padding: 3px;
}
.floor_headings {
  font-size: 16px;
  color: #000;
  background: #fffcf1;
  border-radius: 3px;
  font-family: Gsb;
  padding: 8px;
}
.input_fields_quoteg {
  padding: 15px;
  border: 0.75px solid #969696;
  border-radius: 3px;
  color: #969696;
  font-size: 14px;
  width: 100%;
}
.input_fields_quoteg:focus {
  outline: none;
}
.border_line {
  border-top: 2px dashed #cfcfcf;
}
.pack_btn_parent {
  gap: 10px;
}
.package_btns_qg {
  cursor: pointer;
  padding: 20px;
  text-align: center;
  border: 2.5px dashed #969696;
  border-radius: 4px;
  height: 100px;
}
.Package_name {
  font-family: Gm;
}
.activeButton {
  border: 1.2px solid #fcc216;
}
.total_cost_div span {
  color: #000;
  font-family: Gm;
}
.total_cost_div p {
  color: #000;
  font-family: Gsb;
  font-size: 20px;
}
.labels_div {
  width: 90%;
  position: absolute;
  margin-left: -5rem;
}
/* css for final pdf generated quote */
.strip_top {
  background: radial-gradient(
      116.82% 1273.18% at -2.17% -68.16%,
      #696969 0%,
      #0e0e0e 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  height: 20px;
}
.company_address p {
  font-family: Gb;
  font-size: 16px;
  color: #000;
}
.company_address span {
  font-family: Gsb;
  font-size: 12px;
  color: #969696;
}
.customer_info {
  border-bottom: 1px solid #d7d7d7;
}
.customer_info p {
  color: #fcc216;
  font-family: Gsb;
}
.customer_content {
  font-family: Gsb;
  font-size: 12px;
}
.border_lineq {
  border-top: 2px dashed #e6e6e6;
}
.floor_details p {
  color: #000;
  font-family: Gsb;
  font-family: 15px;
}
.table_for_floor_info {
  width: 100%;
}
.floor_table {
  width: 100%;
}
thead .table_header th {
  background-color: #f7ba00 !important;
  color: #000;
  padding: 10px;
}
.payment_details_nschedule {
  color: #000;
  font-family: Gsb;
  font-size: 14px;
}
.original_color {
  color: #000 !important;
}
.area_details_label {
  font-family: Gm;
}
.floor_inc {
  border: 0.75px solid #969696;
  padding: 4px;
  margin-left: 10px;
}
.padding_for_table {
  padding: 10px;
}
.view_doc_btn {
  background: #f4f4f4;
  border-radius: 2px;
  padding: 10px;
}
