@font-face {
  font-family: "Mr";
  src: local("Mr"),
    url(../../../Fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Mm";
  src: local("Mm"), url(../../../Fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Mb";
  src: local("Mb"), url(../../../Fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Sb";
  src: local("Mb"),
    url(../../../Fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gm";
  src: local("Gm"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gr";
  src: local("Gr"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gsb";
  src: local("Gsb"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gb";
  src: local("Gb"),
    url("../../../Fonts/Gilroy\ Font/Gilroy-Bold.ttf") format("truetype");
}
.document_main_div {
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}
.docs_edit_btn {
  width: 18px !important;
}
.package_box {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 16px;
}
.pack_txt {
  font-family: Gsb;
  color: #000;
}
.package_indicator {
  background: #fcc216;
  border-radius: 2px;
  color: black;
  padding: 2px 12px;
  font-size: 18px;
}
.pack_price {
  color: #969696;
  font-family: Gm;
  font-size: 12px;
}
.edit_pack {
  font-family: Gm;
  color: #fcc216;
  font-size: 12px;
  cursor: pointer;
}
.docs_box {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 8px;
}
.dwnd_btn {
  background: #ffffff;
  width: 100%;
  border-radius: 2px;
  font-family: Gsb;
  padding: 10px;
  font-size: 12px;
}
.pack_txt1 {
  font-family: Gsb;
  color: #000;
  font-size: 12px;
  height: 38px;
}
.download_ico {
  color: #fcc216;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

input:focus + .slider {
  box-shadow: 0 0 1px #fcc216;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.remove_btn {
  background: rgba(254, 62, 62, 0.05);
  border-radius: 2px;
  width: 100%;
  color: #fe3e3e;
  font-family: Gm;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  border: none;
  outline: none;
  padding: 10px;
}
.delpackage_btn {
  color: #fe3e3e;
}
.popup-overlay1-content {
  margin: auto;
  padding: 10px;
  width: 40% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.save_changes_btn {
  width: 50% !important;
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
  border-radius: 3px;
  font-family: Gm;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  border: none;
  outline: none;
  padding: 14px;
}
.package_headings {
  font-family: Gsb;
}
