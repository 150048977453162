#SwitchDiv {
  width: 45px;
  height: 25px;
}

#innerDiv {
  background: #969696;
  width: 45px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 2.5px;
}

#SwitchActive {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  width: 45px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 2.5px;
}

#toggleBubble {
  background: #ffffff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}
