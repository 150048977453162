.App {
  font-family: sans-serif;
}

ul {
  margin: 0;
}

.dropzone {
  text-align: center;
  padding: 20px;

  color: #bdbdbd;
  margin-bottom: 20px;
  height: 60vh;
  display: flex;
  align-items: center;

  background: #ffffff;
  border: 1px dashed #908f8f;
  border-radius: 10px;
}
