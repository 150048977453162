p {
  margin-bottom: 0px;
}

#setDiv {
  margin: 20px 15px 10px 225px;
  padding: 25px 25px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

#setHeader {
  color: black;
  font-family: "gsb";
  margin-bottom: 0px;
}

.contentDiv {
  background: #ffffff;
  border-radius: 2px;
  padding: 15px 25px;
  margin-top: 15px;
}

.contHead {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.addBtn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  color: black;
  font-family: "gsb";
  padding: 5px 15px;
  font-size: 12px;
}

.editBtn {
  background: #f5f5f5;
  border-radius: 2px;
  color: black;
  font-family: "gsb";
  padding: 5px 15px;
  font-size: 12px;
  margin-right: 15px;
}

.cellDiv {
  height: max-content;
  width: auto;
  margin: 5px;
  border-radius: 3px;
}

.viewContentDiv {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  margin-top: 15px;
  border-radius: 3px;
}

.imgDiv {
  width: 247px;
  height: 177px;
  background-color: grey;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.details {
  height: 35%;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rank {
  background-color: white;
  padding: 9px 10px;
  border-radius: 3px;
  margin-right: 15px;
  color: black;
  font-family: "gsb";
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projName {
  font-size: 17px;
  color: #0e0e0e;
  font-family: "gsb";
}

.viewDets {
  font-size: 13px;
  color: #fcc216;
  margin-top: 2px;
  font-family: "gm";
}

input,
select,
textarea {
  color: black;
}

.rankInput {
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 3px;
  color: #0e0e0e;
  text-align: center;
  margin-right: 15px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  font-family: "gsb";
}

.rankInput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.TabSec {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.tabs {
  margin: 0;
  min-width: 165px;
  font-family: "gm";
  color: #cdcdcd;
  border-bottom: 1.5px solid #cdcdcd;
  text-align: center;
  cursor: pointer;
}
.activeTab {
  margin: 0;
  min-width: 165px;
  font-family: "gm";
  color: black;
  border-bottom: 1px solid black;
  text-align: center;
  cursor: pointer;
}
