.cellRowDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  background-color: #f6f6f6;
  padding: 10px 15px;
  border-radius: 3px;
}

.rowInfoText {
  color: black;
  font-family: "gsb";
  font-weight: 400;
  font-size: 15px;
  margin-top: 0px;
  text-align: center;
}

.rowRanknProjName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowLabels {
  font-family: "gm";
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}

.projRowRank {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.viewDetsRowBtn {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.projectValue {
  width: 115px;
  height: 32px;
  background: #3add5e;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.gripper {
  width: 6px;
  height: 14px;
  margin-right: 15px;
}

.popupFormRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.marginR15 {
  margin-right: 15px;
}

.popupFormDiv {
  padding-right: 1px;
  border-right: 1px solid #f2f2f2;
}

.popupImgDiv {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.popupBiSectionDiv {
  display: flex;
  flex-direction: row;
}

.popupSubmitSm {
  width: 154px;
  height: 42px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  font-family: "gm";
  color: black;
  font-size: 14px;
  margin-right: 15px;
}

.popupDeleteSm {
  width: 154px;
  height: 42px;
  background: #cfcfcf;
  border-radius: 2px;
  font-family: "gm";
  color: black;
  font-size: 14px;
  margin-right: 15px;
}

.checkBxContainer {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100px;
}

.settingCheckbox {
  margin-right: 5px;
}

.popupInputLg {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  height: 50px;
  width: 375px;
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 5px;
}

.imgPrev {
  width: 128px;
  height: 84px;
  border-radius: 2px;
  object-fit: cover;
  margin-top: 15px;
  margin-right: 15px;
  z-index: 1;
}

.hoverDel {
  display: none;
}

.imgParent:hover .hoverDel {
  display: block;
  position: absolute;
  width: 128px;
  height: 84px;
  border-radius: 2px;
  object-fit: cover;
  margin-top: 15px;
  margin-right: 15px;
  image-rendering: -webkit-optimize-contrast;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 128px;
  height: 84px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 128px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #96969696;
  border-radius: 3px;
  margin-top: 15px;
  background-color: #fffdf9;
}

.addimgbtn {
  color: #f7ba00;
  background-color: transparent;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.addProjText {
  font-family: "gsb";
  font-size: 13px;
  color: black;
}

.tabsNbtns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0px;
  align-items: center;
}

.catTabs {
  display: flex;
  flex-direction: row;
  background: #f5f5f5;
  border-radius: 2px;
  width: max-content;
  padding: 5px;
}

.catCells {
  width: 85px;
  height: 32px;
  border-radius: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: black;
  margin-top: 0px;
  font-family: "gm";
  cursor: pointer;
}

.popupRow {
  display: flex;
  flex-direction: row;
}

.popupTableHead {
  padding: 10px 0px;
  border-bottom: 1px solid #cfcfcf;
}

.popupDivL {
  width: 70%;
  margin-right: 15px;
  margin-top: 15px;
}

.popupDivR {
  width: 30%;
  margin-top: 15px;
}

.catDelete {
  background: #fff0f0;
  border-radius: 3px;
  font-family: "gm";
  font-size: 14px;
  padding: 5px 15px;
  color: #fe3e3e;
  margin-right: 15px;
}

.catAdd {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 3px;
}
